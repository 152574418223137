import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blogs from "./pages/Blogs/Blogs";
import Contact from "./pages/Contact/Contact";
import HeaderStyleFive from "./pages/HeaderStyleFive/HeaderStyleFive";
import HeaderStyleFour from "./pages/HeaderStyleFour/HeaderStyleFour";
import HeaderStyleSeven from "./pages/HeaderStyleSeven/HeaderStyleSeven";
import HeaderStyleSix from "./pages/HeaderStyleSix/HeaderStyleSix";
import Home from './pages/Home/Home';
import HomeThree from "./pages/HomeThree/HomeThree";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import Portfolio from "./pages/Portfolio/Portfolio";
import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
import Services from "./pages/Services/Services";
import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
import Team from "./pages/Team/Team";
import TeamDetails from "./pages/TeamDetails/TeamDetails";
import IndustrialServices from "./components/Servicepages/IndustrialService";
import IctServices from "./components/Servicepages/IctService";
import CommercialServices from "./components/Servicepages/CommercialService";
import ConstructionServices from "./components/Servicepages/ConstructionService";
import ConsultancyServices from "./components/Servicepages/ConsultancyService";
import AgriculturalServices from "./components/Servicepages/AgriculturalService";
import ProcurementServices from "./components/Servicepages/ProcurementService";
import EventManagement from "./components/Servicepages/EventManagement";
import OurValues from "./pages/OurValues/OurValues";
import Careers from "./pages/Careers/Careers";
import ManagementServices from "./components/Servicepages/ManagementService";
import LeadershipDevelopmentServices from "./components/Servicepages/Leadership";
import LogisticsCourierServices from "./components/Servicepages/LogisticsCourier";
import FinancialAdvisoryServices from "./components/Servicepages/FinancialAdvisory";


function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<HomeTwo/>} />
            <Route path="/home" element={<HomeTwo/>} />
            <Route path="/homeTwo" element={<HomeTwo/>} />
            <Route path="/homeThree" element={<HomeThree/>} />
            <Route path="/headerStyleFour" element={<HeaderStyleFour/>} />
            <Route path="/headerStyleFive" element={<HeaderStyleFive/>} />
            <Route path="/headerStyleSix" element={<HeaderStyleSix/>} />
            <Route path="/headerStyleSeven" element={<HeaderStyleSeven/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/careers" element={<Careers/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/our-values" element={<OurValues/>} />
            <Route path="/servicesDetails" element={<ServicesDetails/>} />
            <Route path="/industrial-service" element={<IndustrialServices/>} />
            <Route path="/commercial-service" element={<CommercialServices/>} />
            <Route path="/construction-service" element={<ConstructionServices/>} />
            <Route path="/consultancy-service" element={<ConsultancyServices/>} />
            <Route path="/agricultural-service" element={<AgriculturalServices/>} />
            <Route path="/procurement-service" element={<ProcurementServices/>} />
            <Route path="/event-management" element={<EventManagement/>} />
            <Route path="/management-service" element={<ManagementServices/>} />
            <Route path="/leadership-development-service" element={<LeadershipDevelopmentServices/>} />
            <Route path="/logistics-and-courier-services" element={<LogisticsCourierServices/>} />
            <Route path="/financial-advisory-services" element={<FinancialAdvisoryServices/>} />
            <Route path="/ict-service" element={<IctServices/>} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/portfolioDetails" element={<PortfolioDetails/>} />
            <Route path="/team" element={<Team/>} />
            <Route path="/teamDetails" element={<TeamDetails/>} />
            <Route path="/blogs" element={<Blogs/>} />
            <Route path="/blogDetails" element={<BlogDetails/>} />
            <Route path="/contact" element={<Contact/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
